@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  padding-bottom: 50px;
}

a {
  text-decoration: none;
  color: turquoise;
  font: bold;
  font-size: large;
}
a:hover {
  text-decoration: none;
}
.underline {
  position: relative;
}
.underline:after {
  content: "";
  position: absolute;
  bottom: -3px;
  height: 3px;
  width: 0;
  right: 0;
  background-color: whitesmoke;
  transition: all 300ms ease;
}
.underline:hover:after {
  left: 0;
  width: 100%;
}

h1 {
  font-size: 50px;
}

.btn {
  max-width: 100%;
  width: 110px;
  max-height: 100%;
  height: 35px;
  border-radius: 50px;
  border: none;
  color: turquoise;
  font: bold;
  font-size: large;
  align-items: center;
  transition: 500ms ease-in-out;
}
.btn:hover {
  cursor: pointer;
  color: whitesmoke;
  background-color: green;
  transform: scale(1.1);
}

.menu {
  display: flex;
}
.btn__menu:hover {
  cursor: pointer;
  color: turquoise;
  transform: scale(1.1);
}

.btn__menu {
  display: none;
  background-color: transparent;
  font-size: 40px;
  border: none;
  color: gray;
  display: none;
  transition: 500ms ease-in-out;
}

.menu__backdrop {
  position: absolute;
  background-color: #242424;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  transition: all 500ms ease;
  transform: translate(100%);
  opacity: 0;
}

.menu--open {
  display: flex;
  max-height: 100vh;
  overflow: hidden;
}

.menu--open .menu__backdrop {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
}

.menu__links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu__list {
  padding: 32px 0;
  list-style-type: none;
}

.menu__link {
  font-size: 40px;
  color: white;
  background: transparent;
  border: none;
  transition: 500ms ease-in-out;
}

.menu__link:hover {
  cursor: pointer;
  color: turquoise;
  transform: scale(1.1);
}

.btn__menu--close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  padding: 8px;
  color: black;
}

/* HEADER */

.background {
  position: absolute;
  width: 100%;
  height: 45%;
  z-index: -1;
  opacity: 0.7;
}

.fa-video-camera {
  color: turquoise;
  font-size: 35px !important;
  padding-left: 20px;
}

.pic-movie {
  max-width: 150px;
  max-height: 50px;
}

.header-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

.header-options {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 100%;
  width: 500px;
}

.title {
  align-items: center;
  text-align: center;
  padding-top: 50px;
}

.main-title {
  font-style: italic;
  -webkit-text-stroke: 1px whitesmoke;
  color: black;
}
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

input {
  border-radius: 100px;
  height: 50px;
  max-width: 600px;
  width: 100%;
  font-size: 20px;
  padding-left: 20px;
  margin-left: 30px;
  border: none;
  outline: none;
}

#searchBtn {
  position: relative;
  color: black;
  font-size: 30px !important;
  right: 50px;
  background: none;
  border: none;
}
.fa-search {
    transition: 500ms ease-in-out;
}
.fa-search:hover {
  cursor: pointer;
  color: #40e0d0;
  transition: 500ms ease-in-out;
}

/* BODY */

.movies {
  padding-top: 150px;
  text-align: center;
}

.filter-bar {
    display: flex;
    justify-content: end;
    gap: 10px;
    position: relative;
    bottom: 30px;
    right: 20px;
}
#combinedFilter {
    transition: 500ms ease-in-out;
    border-radius: 15px;
    text-align: center;
    appearance: none;
}
#combinedFilter:hover {
    cursor: pointer;
    background-color: #242424;
    color: white;
    transition: 500ms ease-in-out;
}

select {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
}

.results {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 50px;
}

.skeleton-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 50px;
    padding-top: 150px;
    padding-right: 50px;
    padding-left: 50px;
}

.skeleton {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.skeleton-poster {
    width: 300px;
    height: 445px;
    background-color: #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 35px;
    margin-right: 15px;
    margin-left: 15px;
    text-align: center;
}

.skeleton-text {
    width: 120px;
    height: 15px;
    background-color: #ddd;
    border-radius: 4px;
    margin-bottom: 5px;
}

#spinner {
  visibility: hidden;
  font-size: 100px;
  color: black;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.fa-spinner {
  color: black;
  animation: spinner 1.5s infinite linear;
  color: black;
}
@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.movie {
  display: flex;
  justify-content: space-evenly;
  flex: wrap;
  transition: opacity 500ms ease-in-out;
}

.movie-card {
  padding: 35px;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  transition: 500ms ease;
}
.movie-card:hover {
  cursor: pointer;
  transform: scale(1.1);
}
p {
  opacity: 0;
  font-weight: bolder;
  transition: opacity 500ms ease;
  color: black;
}
.movie-card:hover p {
  opacity: 1;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: gray;
  color: black;
  border: none;
  border-radius: 50%;
  padding: 10px 15px;
  font-size: 24px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: 500ms ease-in-out;
}
.back-to-top:hover {
  transform: scale(1.2);
  color: green;
  background-color: transparent;
}

.back-to-top.show {
  opacity: 1;
  visibility: visible;
}

/* SMALL DISPLAY */
@media (max-width: 768px) {
  .btn__menu {
    display: block;
  }

  .header-title {
    padding-right: 20px;
    justify-content: space-between;
  }

  .header-options {
    display: none;
  }

  .filter-bar{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px;
    gap: 10px;
    top: 0px;
  }
  
  .movie-details {
    display: flex;
    flex-direction: column;
  }

  .movie-poster {
    height: 600px;
    width: 300px auto;
    padding-top: 20px;
  }
}



/* HOME PAGE */

.header {
  padding-top: 25px;
  padding-right: 100px;
  padding-left: 100px;
  padding-bottom: 50px;
  background: linear-gradient(to bottom,
  rgba(240, 239, 244, 0.5), 
  rgba(0, 0, 0, 0.644),
  rgba(255, 255, 255, 0.5));
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.body-container {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 50px;
  color: black;
  background-color: rgba(68, 68, 68, 0.473);
}



.subtitle {
  font-style: italic;
  -webkit-text-stroke: .8px black;
  color: white;
  font-weight: 1000;
}

.home-background {
  position: absolute;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  z-index: -1;
  opacity: .9;
}

.underline-current {
  text-decoration: underline;
  color: silver;
  border: none;
  background-color: transparent;
  font-size: large;
  cursor: pointer;
  transition: 500ms ease-in-out;
}
.underline-current:hover {
  color: #40e0d0;
}

.black {
  padding-left: 5px;
  color: black;
  font-weight: 1000;
}

.green {
  color: #40e0d0;
}


/* MOVIE */

.movie-details {
  padding: 50px;
  display: flex;
  gap: 50px;
  top: 100px;
  background: linear-gradient(to bottom, /* Direction of the gradient */
  rgba(240, 239, 244, 0.5), /* Top color - Red with 50% transparency */
  rgba(0, 174, 255, 0.5), /* Middle color - Green with 50% transparency */
  rgba(255, 255, 255, 0.5));
}

.back-arrow {
  cursor: pointer;
  border: none;
  height: 0;
  width: 100px;
  font-size: 50px;
  transition: 500ms ease-in-out;
}
.fa-arrow-circle-left {
  transition: transform 500ms ease-in-out;
}
.fa-arrow-circle-left:hover {
  color:#40e0d0;
  transform: scale(1.5);
}

.movie-details--info {
  display: flex;
  flex-direction: column;
  gap: 50px;
  text-align: center;
  font-weight: 100;
}

.movie-info {
  opacity: 1;
}
strong {
  font-size: 25px;
}

.movie-poster {
  width: 800px;
  height: auto;
  max-width: 100%;
}

